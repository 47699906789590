import { omit, includes } from "lodash";

import { isBefore, parseISO } from "date-fns";

/**
 * Handler for the `checkinRequestUpdated` subscription event.
 * @param {*} previousResult Current checkin requests list
 * @param {*} payload  Newly created or existing updated checkin request
 * @param {*} includeStatuses Which statuses to include. If not one of these statuses, the payload is removed.
 * @returns Next checkin requests list (with update checkin request)
 */
export const handleCheckinRequestUpdated = (
  previousResult,
  payload,
  includeStatuses = ["pending", "submitted", "waiting_drs_buy"]
) => {
  // Return `previousResult` if `payload` is falsy
  if (!payload) return previousResult;

  // If the checkin request is not in the cache, just return `previousResult`
  let existingCheckinRequestIndex = previousResult.checkinRequests.findIndex(
    (checkinRequest) => checkinRequest.id === payload.id
  );

  if (existingCheckinRequestIndex < 0) {
    if (includes(includeStatuses, payload.status)) {
      // We have a new checkin request. Add the payload
      // to the `previousResult.checkinRequests` array.
      previousResult.checkinRequests.push(payload);
    }
    // Return the updated data
    return Object.assign({}, previousResult);
  }

  // If we've made it this far, we found the existing checkin request. Update `checkin request`
  // with `payload`, as long as `payload.updatedAt` comes after
  // `checkin request.updatedAt`. Then, update the `previousResult.checkinRequests` array.
  const existingCheckinRequest =
    previousResult.checkinRequests[existingCheckinRequestIndex];
  if (payloadPrecedesCache(payload, existingCheckinRequest))
    return previousResult;

  // Stop gap to test and see if a race condition is occurring:
  //  Make sure we don't overwrite a good containerNum or
  //  containerDescription with 0 or nil
  if (!payload.containerNum && existingCheckinRequest.containerNum) {
    payload = omit(payload, "containerNum");
  }
  if (
    !payload.containerDescription &&
    existingCheckinRequest.containerDescription
  ) {
    payload = omit(payload, "containerDescription");
  }

  if (includes(includeStatuses, payload.status) && !payload.void) {
    previousResult.checkinRequests.splice(existingCheckinRequestIndex, 1, {
      ...existingCheckinRequest,
      ...payload,
    });
  } else {
    // If the status is not one of the included ones, just remove the updated item from list!
    previousResult.checkinRequests.splice(existingCheckinRequestIndex, 1);
  }

  // Return the updated data
  return { ...previousResult };
};

// Private
const payloadPrecedesCache = ({ updatedAt: payload }, { updatedAt: cache }) => {
  const valueToDate = (time) =>
    (typeof time === "string" && parseISO(time)) || time;
  return isBefore(valueToDate(payload), valueToDate(cache));
};
