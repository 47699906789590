import { omit } from "lodash";

import { isBefore, parseISO } from "date-fns";

/**
 * Handler for the `buyUpdated` subscription event. Seems that is the only event we receive currently, so it must handle both
 * creation and update
 * @param {*} previousResult Current buys list
 * @param {*} payload  Newly created or existing updated buy
 * @returns Next buys list (with added buy or just updated if it existed already)
 */
export const handleBuyCreateUpdateVoid = (previousResult, payload) => {
  // Return `previousResult` if `payload` is falsy
  if (!payload) return previousResult;

  if (payload.voided) {
    // Try to find and remove the buy
    return hadleBuyVoided(previousResult, payload);
  }

  return handleBuyCreatedOrUpdated(previousResult, payload);
};

/**
 * Handler for the `buyUpdated` subscription event when the buy is voided
 * @param {*} previousResult  Current buys list
 * @param {*} payload Updated buy
 * @returns Next buys list (with the buy removed if it had a newer date)
 */
const hadleBuyVoided = (previousResult, payload) => {
  // If the buy is not in the cache, just return `previousResult`
  let existingBuyIndex = previousResult.buys.findIndex(
    (buy) => buy.id === payload.id
  );
  if (existingBuyIndex < 0) {
    return previousResult;
  }
  // If we've made it this far, we found the existing buy. Update `existingBuy`
  // with `payload`, as long as `payload.updatedAt` comes after
  // `existingBuy.updatedAt`. Then, update the `previousResult.buys` array.
  const existingBuy = previousResult.buys[existingBuyIndex];
  if (payloadPrecedesCache(payload, existingBuy)) return previousResult;

  previousResult.buys.splice(existingBuyIndex, 1);
  // Return the updated data
  return { ...previousResult };
};

/**
 * Handler for the `buyUpdated` subscription event
 * @param {*} previousResult  Current buys list
 * @param {*} payload Updated buy
 * @returns Next buys list (with the buy updated if it had a newer date)
 * TODO:  There might be a race condition where we're overwriting `container_num` inadvertently.
 *  -> seems that the race condition is fixed, but leaving the comment here for after QA
 */
const handleBuyCreatedOrUpdated = (previousResult, payload) => {
  // If the buy is not in the cache, just return `previousResult`
  let existingBuyIndex = previousResult.buys.findIndex(
    (buy) => buy.id === payload.id
  );
  if (existingBuyIndex < 0) {
    // We have a new buy. Add the payload
    // to the `previousResult.buys` array.
    previousResult.buys.push(payload);
    // Return the updated data
    return Object.assign({}, previousResult);
  }

  // If we've made it this far, we found the existing buy. Update `existingBuy`
  // with `payload`, as long as `payload.updatedAt` comes after
  // `existingBuy.updatedAt`. Then, update the `previousResult.buys` array.
  const existingBuy = previousResult.buys[existingBuyIndex];
  if (payloadPrecedesCache(payload, existingBuy)) return previousResult;

  // Stop gap to test and see if a race condition is occurring:
  //  Make sure we don't overwrite a good containerNum or
  //  containerDescription with 0 or nil
  if (!payload.containerNum && existingBuy.containerNum) {
    payload = omit(payload, "containerNum");
  }
  if (!payload.containerDescription && existingBuy.containerDescription) {
    payload = omit(payload, "containerDescription");
  }

  previousResult.buys.splice(existingBuyIndex, 1, {
    ...existingBuy,
    ...payload,
  });
  // Return the updated data
  return { ...previousResult };
};

// Private
const payloadPrecedesCache = ({ updatedAt: payload }, { updatedAt: cache }) => {
  const valueToDate = (time) =>
    (typeof time === "string" && parseISO(time)) || time;
  return isBefore(valueToDate(payload), valueToDate(cache));
};
